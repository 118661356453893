import { createRouter, createWebHistory } from 'vue-router'
import layout from '../layout/layout.vue'

const routes = [

  {
    path: '/404',
    name: 'error',
    component: () => import(/* webpackChunkName: "404" */ '../components/NotFound.vue')
  },
  {
    path: '/',
    redirect: {path: '/Home'}
  },
  {
    path: '/login',
    // alias:'/',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/Home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: {value: 'Inicio', requiresAuth: true}, // esto se debe colocar en true luego de terminar la vista
    children: [
      {
        path: '/Tablero',
        name: '',
        component: layout,
        meta: {value: 'Tablero'},
        children: [
          {
            path: '',
            name: 'Tablero',
            component: () => import(/* webpackChunkName: "tablero" */ '../views/Tablero/Tablero.vue'),
          },
          {
            path: 'CargarPagos',
            name: 'CargarPagos',
            component: () => import(/* webpackChunkName: "pagos" */ '../views/Tablero/CargarPagos.vue'),
            meta: {value: 'Cargar Pagos'}
          }
        ]
      },
      {
        path: '/Sistema/:page?',
        name: 'Sistema',
        component: layout,
        meta: {value: 'Sistema'},
        children: [

          // {
          //   path: '/TableroUsuario/:page?',
          //   alias: '',
          //   name: '',
          //   component: layout,
          //   meta: {value: 'Usuarios'},
          //   children: [
          //     {
          //       path: '',
          //       name: 'TableroUsuario',
          //       component: () => import(/* webpackChunkName: "tablero" */ '../views/Sistema/Usuarios/TableroUsuario.vue'),
          //     },
          //     {
          //       path: '/nuevoUsuario/:page?',
          //       name: 'nuevoUsuario',
          //       component: () => import(/* webpackChunkName: "login" */ '../views/Sistema/Usuarios/NuevoUsuario.vue'),
          //       meta: {value: 'Nuevo Usuario'}
          //     },
          //   ]
          // },

          {
            path: '/TableroEmails/:page?',
            name: '',
            component: layout,
            meta: {value: 'Emails enviados'},
            children: [
              {
                path: '',
                name: 'TableroEmails',
                component: () => import(/* webpackChunkName: "Configuraciones" */ '../views/Sistema/Emails/TableroEmails.vue')
              }
            ]
          },

          {
            path: '/TableroCiudades/:page?',
            name: '',
            component: layout,
            meta: {value: 'Ciudades'},
            children: [
              {
                path: '',
                name: 'TableroCiudades',
                component: () => import(/* webpackChunkName: "Configuraciones" */ '../views/Sistema/Ciudades/TableroCiudades.vue')
              },
              {
                path: '/NuevaCiudad/:page?',
                name: 'NuevaCiudad',
                component: () => import(/* webpackChunkName: "NuevoCiudad" */ '../views/Sistema/Ciudades/NuevaCiudad.vue'),
                meta: {value: 'Nueva Ciudad'}
              },
              {
                path: '/EditarCiudad/:id/:page?',
                name: 'EditarCiudad',
                component: () => import(/* webpackChunkName: "404" */ '../views/Sistema/Ciudades/EditarCiudad.vue'),
                meta: {value: 'Editar Ciudad'}
              },
            ]
          },

          {
            path: '/Configuraciones/:page?',
            name: '',
            component: layout,
            meta: {value: 'Configuraciones'},
            children: [
              {
                path: '',
                name: 'Configuraciones',
                component: () => import(/* webpackChunkName: "Configuraciones" */ '../views/Sistema/Configuraciones/Configuraciones.vue'),
              },
              {
                path: '/ConfiguracionDeMapa/:page?',
                name: 'ConfiguracionDeMapa',
                component: () => import(/* webpackChunkName: "Configuraciones" */ '../views/Sistema/Configuraciones/ConfiguracionDeMapa.vue'),
                meta: {value: 'Configuracion mapa de Google'}
              },

              {
                path: '/IntegracionEnvioCorreo/:page?',
                name: 'IntegracionEnvioCorreo',
                component: () => import(/* webpackChunkName: "IntegracionEnvioCorreo" */ '../views/Sistema/Configuraciones/IntegracionEnvioCorreo.vue'),
                meta: {value: 'Integracion con Envio de Correo'}
              },

            ]
          }
        ]
      },
      {
        path: '/PuntosWiFi',
        name: 'PuntosWiFi',
        component: layout,
        children: [
          {
            path:'/TableroPuntoWifi/:page?',
            name: '',
            meta: {value: 'Puntos WiFi'},
            component: layout,
            children: [
              {
                path: '',
                name: 'TableroPuntoWifi',
                component: () => import(/* webpackChunkName: "TableroPuntoWifi" */ '../views/PuntosWifi/TableroPuntoWiFi.vue'),
              },
              {
                path: '/NuevoPuntoWifi/:page?',
                name: 'NuevoPuntoWifi',
                meta: {value: 'Nuevo Punto WiFi'},
                component: () => import(/* webpackChunkName: "NuevoPuntoWifi" */ '../views/PuntosWifi/NuevoPuntoWifi.vue')
              },
              {
                path: '/EditarPuntoWifi/:id/:page?',
                name: 'EditarPuntoWifi',
                meta: {value: 'Editar Punto WiFi'},
                component: () => import(/* webpackChunkName: "EditarPuntoWifi" */ '../views/PuntosWifi/EditarPuntoWifi.vue')
              },


            ]
          },

        ],
      },
      {
        path: '/Anunciantes',
        name: 'Anunciantes',
        component: layout,
        children: [
          {
            path: '/TableroAnunciante/:page?',
            name: '',
            component: layout,
            meta: {value: 'Tablero Anunciante'},
            children: [
              {
                path: '',
                name: 'TableroAnunciante',
                component: () => import(/* webpackChunkName: "tableroAnunciante" */ '../views/Anunciante/TableroAnunciante.vue'),
              },
              {
                path: '/AgregarAnunciante/:page?',
                name: 'AgregarAnunciante',
                component: () => import(/* webpackChunkName: "NuevoAnunciante" */ '../views/Anunciante/NuevoAnunciante.vue'),
                meta: {value: 'Agregar Anunciante'}
              },
              {
                path: '/EdicionAnunciante/:id/:page?',
                name: 'EdicionAnunciante',
                component: () => import(/* webpackChunkName: "EdicionAnunciante" */ '../views/Anunciante/EdicionAnunciante.vue'),
                meta: {value: 'Editar Anunciante'}
              } ,


            ]
          }
        ]
      },
      {
        path: '/Impresiones',
        name: 'Impresiones',
        component: layout,
        children: [
          {
            path: '/TableroImpresiones/:page?',
            name: '',
            component: layout,
            meta: {value: 'Impresiones'},
            children: [
              {
                path: '',
                name: 'TableroImpresiones',
                component: () => import(/* webpackChunkName: "tableroAnunciante" */ '../views/Impresiones/TableroImpresiones.vue'),
              }
            ]
          }
        ]
      },
      {
        path: '/Anuncios',
        name: 'Anuncios',
        component: layout,
        children: [
          {
            path: 'TableroAnuncios/:page?',
            name: '',
            component: layout,
            meta: {value: 'Tablero de Anuncios'},
            children: [
              {
                path: '',
                name: 'TableroAnuncios',
                component: () => import(/* webpackChunkName: "TableroAnuncios" */ '../views/Anuncios/TableroAnuncio.vue'),

              },
              {
                path: '/NuevoAnuncio/:page?',
                name: 'NuevoAnuncio',
                component: () => import(/* webpackChunkName: "NuevoAnunciante" */ '../views/Anuncios/NuevoAnuncio.vue'),
                meta: {value: 'Nuevo Anuncio'}

              },
              {
                path: '/EditarAnuncio/:id/:page?',
                name: 'EditarAnuncio',
                component: () => import(/* webpackChunkName: "NuevoAnunciante" */ '../views/Anuncios/EditarAnuncio.vue'),
                meta: {value: 'Editar Anuncio'}

              },

            ]
          }
        ]
      },
      // {
      //   path: '/Mapa',
      //   name: 'Mapa',
      //   component: () => import(/* webpackChunkName: "NuevoAnunciante" */ '../views/Mapa/Mapa.vue'),
      //   meta: {value: 'Mapa'}
      // },
      {
        path: 'MiPerfil',
        name: 'Mi Perfil',
        component: () => import(/* webpackChunkName: "MiPerfil" */ '../views/Sistema/Usuarios/MiPerfil.vue'),
        meta: {value: 'Mi perfil'}
      }


    ]
  },
  {
    path: '/Img.puwic.com/:id',
    name: 'Imagen_de_anuncio',
    component: () => import(/* webpackChunkName: "imagenDeAnuncio" */ '../views/ImagenDeAnuncio.vue'),
    meta: {requiresAuth: false}
  }



]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (!to.matched.length) {
    next({name: 'error'})
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('user_token') && localStorage.getItem('habilitado') == 'true') {
      next();
    } else {
      next({ name: "Login" });
    }
  } else {
    next();
  }
});


export default router
