import { createStore } from 'vuex';
import {ref} from 'vue'

const store = createStore({
    state(){
        return {
            // Son las rutas que esta en el menu
            listas: [
                {nombre: 'Tablero', activo: false, link: true, name_link: "Tablero", icon: 'fas fa-table has-text-black'},
                // {nombre: 'Mapa', activo: false, link:true, name_link: 'Mapa',icon: 'fas fa-map-marked-alt has-text-black', opc: []},
                {nombre: 'Anunciantes', activo: false, link: true, name_link: 'TableroAnunciante', icon: 'fas fa-user-friends has-text-black', opc: []},
                {nombre: 'Anuncios', activo: false, link:true, name_link: 'TableroAnuncios', icon: 'fas fa-bullhorn has-text-black', opc: []},
                {nombre: 'Impresiones', activo: false, link:true, name_link: 'TableroImpresiones', icon: 'fas fa-eye has-text-black'},
                {nombre: 'Puntos WiFi', activo: false, link:true, name_link: 'TableroPuntoWifi', icon: 'fas fa-wifi has-text-black', opc: []},
                {nombre: 'Sistema', activo:false, link:false, icon: 'fas fa-cog has-text-black', opc: [
                    // { nombre: 'Usuarios', name_link:'TableroUsuario', activo:false},
                    { nombre: 'Ciudades', name_link:'TableroCiudades', activo:false},
                    { nombre: 'Emails enviados', name_link:'TableroEmails', activo:false},
                    { nombre: 'Configuraciones', name_link:'Configuraciones', activo:false},

                ]},
            ],

            url_backend: 'https://api.puwic.com/graphql',
            // url_backend: 'http://192.168.1.23:88/graphql',
            // url_backend: 'http://192.168.1.64:88/graphql',

            // url_storage: 'http://192.168.1.23:88',
            // url_storage: 'http://192.168.1.64:88',
            url_storage: 'https://api.puwic.com',


            isMobile: false,

            carga_exitosa_ciudad: false,

            edicion_exitosa: false,

            imagenes: []

        }
    },

    mutations: {

        onResize (state) {
            if( window.screen.width < 769 ) {
                state.isMobile = true
            } else {
                state.isMobile = false
            }
        },

        verificar_carga(state,accion){


            // console.log(state.carga_exitosa_ciudad)
            if(accion=='nuevaintegracion'){
                if(state.carga_exitosa_ciudad == true){
                    state.carga_exitosa_ciudad = false
                    // console.log("llego el emnsaje con exito",state.carga_exitosa_ciudad)
                }else{
                    state.carga_exitosa_ciudad = true
                    // console.log("se cargo con exito",state.carga_exitosa_ciudad)
                }
            }
            if(accion=='edicionintegracion'){
                if(state.edicion_exitosa== true){
                    state.edicion_exitosa= false
                    // console.log("llego el emnsaje con exito",state.edicion_exitosa)
                }else{
                    state.edicion_exitosa = true
                    // console.log("se cargo con exito",state.edicion_exitosa)
                }
            }

            if(accion=='nuevaciudad'){
                if(state.carga_exitosa_ciudad == true){
                    state.carga_exitosa_ciudad = false
                    // console.log("llego el emnsaje con exito",state.carga_exitosa_ciudad)
                }else{
                    state.carga_exitosa_ciudad = true
                    // console.log("se cargo con exito",state.carga_exitosa_ciudad)
                }
            }
            if(accion=='edicionciudad'){
                if(state.edicion_exitosa== true){
                    state.edicion_exitosa= false
                    // console.log("llego el emnsaje con exito",state.edicion_exitosa)
                }else{
                    state.edicion_exitosa = true
                    // console.log("se cargo con exito",state.edicion_exitosa)
                }
            }
            if(accion=='nuevoanunciante'){
                if(state.carga_exitosa_ciudad == true){
                    state.carga_exitosa_ciudad = false
                    // console.log("llego el emnsaje con exito",state.carga_exitosa_ciudad)
                }else{
                    state.carga_exitosa_ciudad = true
                    // console.log("se cargo con exito",state.carga_exitosa_ciudad)
                }
            }
            if(accion=='edicionanunciante'){
                if(state.edicion_exitosa== true){
                    state.edicion_exitosa= false
                    // console.log("llego el emnsaje con exito",state.edicion_exitosa)
                }else{
                    state.edicion_exitosa = true
                    // console.log("se cargo con exito",state.edicion_exitosa)
                }
            }
            if(accion=='nuevoanuncio'){
                if(state.carga_exitosa_ciudad == true){
                    state.carga_exitosa_ciudad = false
                    // console.log("llego el emnsaje con exito",state.carga_exitosa_ciudad)
                }else{
                    state.carga_exitosa_ciudad = true
                    // console.log("se cargo con exito",state.carga_exitosa_ciudad)
                }
            }
            if(accion=='edicionanuncio'){
                if(state.edicion_exitosa== true){
                    state.edicion_exitosa= false
                    // console.log("llego el emnsaje con exito",state.edicion_exitosa)
                }else{
                    state.edicion_exitosa = true
                    // console.log("se cargo con exito",state.edicion_exitosa)
                }
            }
            if(accion=='nuevopuntowifi'){
                if(state.carga_exitosa_ciudad == true){
                    state.carga_exitosa_ciudad = false
                    // console.log("llego el emnsaje con exito",state.carga_exitosa_ciudad)
                }else{
                    state.carga_exitosa_ciudad = true
                    // console.log("se cargo con exito",state.carga_exitosa_ciudad)
                }
            }
            if(accion=='edicionpuntowifi'){
                if(state.edicion_exitosa== true){
                    state.edicion_exitosa= false
                    // console.log("llego el emnsaje con exito",state.edicion_exitosa)
                }else{
                    state.edicion_exitosa = true
                    // console.log("se cargo con exito",state.edicion_exitosa)
                }
            }


        },

        paginacion(state, dato){
            dato.info.count = dato.pageInfo.count
            dato.info.pages = dato.pageInfo.lastPage
            if (dato.pageInfo.currentPage + 1 > dato.pageInfo.lastPage) {
                dato.info.next = null
            } else {
                dato.info.next = dato.pageInfo.currentPage + 1
            }
            if (dato.pageInfo.currentPage - 1 < 1) {
                dato.info.prev = null
            } else {
                dato.info.prev = dato.pageInfo.currentPage - 1
            }
            dato.info.page = dato.pageInfo.currentPage
            dato.info.total = dato.pageInfo.total
        }
    }
});

export default store;