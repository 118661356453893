import { VueClipboard } from '@soerenmartius/vue3-clipboard'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import tooltip from "./directives/tooltip.js";
import "@/assets/tooltip.css";
import './registerServiceWorker'

const app = createApp(App)
app.use(router)
app.use(store)
app.use(VueClipboard)
app.directive("tooltip", tooltip)

app.mount('#app')

