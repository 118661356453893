<template>

	<router-view/>

</template>

<script>
import store from '@/store';
import { ref } from '@vue/reactivity';
import { provide } from '@vue/runtime-core';


export default {
	created(){
		store.commit("onResize")
		this.isMobile = store.state.isMobile
	},

	setup(){
		const isMobile = ref(false)

		window.addEventListener("resize", function(){
			store.commit("onResize")
			isMobile.value = store.state.isMobile
		})

		provide('isMobile', isMobile)

		return{
			isMobile
		}
	}
}
</script>

<style lang="scss">
@import '~bulma/css/bulma.css';
@import '~bulma';
@import '~bulma-calendar';


.verde-puwic{
  background-color: #008575;
}

.modal-scrolleable{
  overflow-y: auto;
  overflow-x: hidden ;
  max-height: 500px
}

@media screen and (max-width: 700px) {
    .modal-scrolleable{
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 370px
  }
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.total-element {
    all: unset;
    margin: 0 12px;
    padding: 5px 7px;
    background-color: #008575;
    color: #fff;
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
    font-weight: 500;
}

.pointer{
  cursor: pointer;
}

.pointer:hover{
  background-color: #01AC97;
}

.loader-wrapper {
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  /* background: #fff; */
  opacity: 0;
  z-index: -1;
  transition: opacity .3s;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}

.loader {
  height: 80px;
  width: 80px;

}

.is-active-loading {
  opacity: 1;
  z-index: 1;
}

.is-loading {
  // margin-top: 20px;
  color:#008575;
  border-block-color: inherit;
  position: relative;
}

</style>
